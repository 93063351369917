<template>
  <div class="flex_box">
    <div v-if="config.type == 'text'">
      <van-dialog v-model="show" :show-confirm-button="false">
        <van-picker
          :title="config.title"
          show-toolbar
          :columns="config.columns"
          @confirm="onConfirm"
          @cancel="onCancel"
          @change="onChange"
        />
      </van-dialog>
    </div>
    <div class="time_box" v-else>
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择年月日"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="confirmBtn"
        @cancel="onCancel"
        cancel-button-text="清空"
      />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    config: {
      type: Object,
    },
  },
  data() {
    return {
      value: "",
      show:true,
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2025, 10, 1),
      currentDate: new Date(2021, 0, 17),
    };
  },
  methods: {
    confirmBtn() {
      this.$emit("confirmBtn", this.currentDate,this.config.index);
    },
    //選擇器
    onConfirm(e) {
      this.$emit("onConfirm",e)
    },
    onCancel() {
      this.$emit("onCancel")
    },
    tableNew(){
      this.currentDate= ""
    },
    onChange() {},
  },
};
</script>
<style scoped>
.flex_box {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}
.time_box {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}
</style>
